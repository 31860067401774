import React,{useState, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {commonFunction} from "../util/commonFunctions";
import GenericModal from "../util/genericModal";
//import { toast } from 'react-toastify';

//INTERNAL COMPONENTS
import RestServer from '../../components/cRestServer';
import {producturl, conurl}  from '../../environment';

import {dateFormat} from '../util/DateFormatter';

import {eventfunctions}from "../util/eventFunctions";

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import Card from 'react-bootstrap/Card';
//import Button from 'react-bootstrap/Button';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTicket, faSquare, faCalendarCheck, faHeart, faSquareCheck} from '@fortawesome/free-solid-svg-icons';

function Popular(){

    const history = useNavigate();

    const [userData, setUserData]=useState(null);

    const[events, setEvents]=useState([]);
    const[favData, setFavData]=useState(null);
    const[allEvents, setAllEvents]=useState(null);
    const[eventsToday, setEventsToday]=useState([]);
    const[eventsThisWeek, setEventsThisWeek]=useState([]);
    const[eventsThisWeekend, setEventsThisWeekend]=useState([]);
    //const[filterSelected, setFilterSelected]=useState(null);
    const[filterId, setFilterId]=useState(0)

    const[selectedFilter, setSelectedFilter]=useState(0);

    const rtnEvent=(event)=>{
        console.info("rtnEvent")

       
    }

    const filter=(id)=>{
        console.info(id);
        if(id===0){setEvents(allEvents);}
        if(id===1){setEvents(eventsToday);}
        if(id===2){setEvents(eventsThisWeek);}
        if(id===3){setEvents(eventsThisWeekend);}
        setFilterId(id);
        setSelectedFilter(id)

    }
    const handleFavorites=(eventId)=>{
        GenericModal().doThis();

        if(false){
            
            console.info("handleFavorites->:");
            console.info("userData->: "+ userData);
    
            const data={
                producttype:"EVENT",
                productid:eventId,
                usertype:"EndUser",
                userid:userData.userid
            }
            console.info(data);
            /*
            new RestServer().setUrl(conurl+'/api/cms/addfavorite')
            .setMethod('POST')
            .flagReturnData(true)
            .setPostBody(data)
            .connect()
            .then(response=>{
            const res= response.data;
            if(res.MessageTypeID===1){

            }

            },[]);
            */
        }

    }

    const crop=(e)=>{
        var descripholder =  e;
        var description=null;
        if(descripholder.length <=200){
            description=descripholder;
        }else{
            description=descripholder.substring(0,100)+"..."
        }

        return description;
    }
    const routeClick=(path)=>{
        history(path, {replace:true})
   }

    useEffect(() => {
        const intFavData={
            icon:faHeart,
            iconName:"Add Fav",
            modalHeader:"Add To Favorites",
            modalText:"To add to your favorites please login in the system",
            closeModal:"Cancel",
            actionModal:"Login",
            postData:{
                type:"fav",
                url:conurl+'/api/cms/addfavorite'
            }
        };
        setFavData(intFavData);



        const userData= localStorage.getItem('userdata');
        //console.info(userData);
        setUserData(JSON.parse(userData));

        new RestServer().setUrl(producturl+'/api/prod/latest/10')
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info("/api/prod/latest/10->response: ")
            console.info(response.data)
            const eventsdata=response.data;

           var events=[];
           var eventsToday=[];
           var eventsThisWeek=[];
           var eventsThisWeekend=[];

           eventsdata.map((e)=>{
            console.info("0");
            console.info("map->e: ");
            console.info(e);
                
                //find the earliest start
                var earliestEvent=0;
     
                //find the latest start
                var latestEvent=0;

                e.eventdates.map((t)=>{
                    if(earliestEvent===0){
                        earliestEvent=t.epochtime;
                    }else{
                        if(earliestEvent<=t.epochtime){
                            earliestEvent=t.epochtime;
                        }
                    }
            
                    if(latestEvent===0){
                        latestEvent=t.epochtime;
                    }else{
                        if(earliestEvent>=t.epochtime){
                            latestEvent=t.epochtime;
                        }
                    }
                })
                console.info("1");

                const dateRange ={
                    startDate: earliestEvent,
                    endDate:latestEvent
                };

                console.info("2");

                dateFormat.eventThisWeekend(dateRange);
                if(dateFormat.futureEvent(dateRange)){

                    console.info("futureEvent");
                    events.push(e)
                }else if(dateFormat.eventToday(dateRange)){
                    eventsToday.push(e);
                }else if(dateFormat.eventThisWeek(dateRange)){
                    eventsThisWeek.push(e);
                }else if(dateFormat.eventThisWeekend(dateRange)){
                    eventsThisWeekend.push(e);
                }
            });
            //console.info(events);
            console.info("Filetered Event: ");
            console.info(events);
            setEvents(events);
            setAllEvents(events);
            setEventsToday(eventsToday);
            setEventsThisWeek(eventsThisWeek);
            setEventsThisWeekend(eventsThisWeekend);
            //localStorage.setItem("usercart", null);
        
        });
    },[]);

    return(
        <React.Fragment>
             <Row>
                <Col className="font-size-150 font-heavy8"> Current Events</Col>
            </Row>
            <Row className="p-t-25">
                <Col lg={10}>
                    <span className="font-size-100 font-heavy8">Filters :&nbsp;&nbsp;&nbsp;</span>
                    {
                        (selectedFilter===0)?
                        <button className="icon-text-container-1-selected m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(0)}}><FontAwesomeIcon icon={faSquareCheck} />&nbsp;&nbsp;&nbsp;&nbsp;All</button>
                        :
                        <button className="icon-text-container-1 m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(0)}}><FontAwesomeIcon icon={faSquare} />&nbsp;&nbsp;&nbsp;&nbsp;All</button>
                    }
                    {
                        (selectedFilter===1)?
                        <button className="icon-text-container-1-selected m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(1)}}><FontAwesomeIcon icon={faSquareCheck} />&nbsp;&nbsp;&nbsp;&nbsp;Today</button>
                        :
                        <button className="icon-text-container-1 m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(1)}}><FontAwesomeIcon icon={faSquare} />&nbsp;&nbsp;&nbsp;&nbsp;Today</button>
                    }
                    {
                        (selectedFilter===2)?
                        <button className="icon-text-container-1-selected m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(2)}}><FontAwesomeIcon icon={faSquareCheck} />&nbsp;&nbsp;&nbsp;&nbsp; Week</button>
                        :
                        <button className="icon-text-container-1 m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(2)}}><FontAwesomeIcon icon={faSquare} />&nbsp;&nbsp;&nbsp;&nbsp; Week</button>
                    }
                    {
                        (selectedFilter===3)?
                        <button className="icon-text-container-1-selected m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(3)}}><FontAwesomeIcon icon={faSquareCheck} />&nbsp;&nbsp;&nbsp;&nbsp;Weekend</button>
                        :
                        <button className="icon-text-container-1 m-r-10 font-heavy font-size-75 p-10 p-r-50" onClick={()=>{filter(3)}}><FontAwesomeIcon icon={faSquare} />&nbsp;&nbsp;&nbsp;&nbsp;Weekend</button>
                    }

                 </Col>
            </Row>
            <Row className="p-t-25">
                {
                    events?.map((event)=>{
                        

                        var image = event?.eventimage[0].imageloc+event?.eventimage[0].imagename;
                        var prodloc = "/productdetail/".concat(event?.eventId);
                        
                        return eventfunctions.eventHasStarted(event?.eventActiveDate)?<Col lg={2} xs={10} md={5} className="glass-container-1 m-r-50 m-b-50">
                                      
                        <Row className="p-t-10">
                            <Col>
                                <img src={image}  className="img-container-1 rad-tl-5 rad-tr-5"/>
                            </Col>
                        </Row>
                      
                        <Row className="p-t-25 font-size-100 font-heavy8 p-l-10 p-r-10">
                            <Col>{event?.eventname}</Col>
                        </Row>
                          
                        <Row className="p-t-10 font-size-75 font-heavy p-l-10 p-r-10 clr-1">
                            <Col>{dateFormat.getMMMDDYYY(event?.eventdates[0].epochtime)}</Col>
                        </Row>
                
                        <Row className="p-t-25 font-size-75 p-l-10  p-r-10 container-h-100">
                            <Col>{crop(event.eventdescription)}</Col>
                        </Row>
                        <Row className="p-t-75 p-b-25">
                            <Col>
                                <button className="icon-container-1" onClick={()=>{routeClick(prodloc)}}>
                                    <FontAwesomeIcon icon={faTicket} />
                                    <span className="icon-container-1-title">Get Tickets
                                        <div className="icon-container-1-title-pointer"></div>
                                    </span>
                                </button>
                            </Col>
                
                            <Col className="right">
                                <GenericModal data={favData} prodid={event.eventId} />
                        
                            </Col>
                        </Row>
                    </Col> :''
                       
                                
                                
                    })
                }
                   


                </Row>
        </React.Fragment>
    );
}
export default Popular;