'use strict';
import moment from 'moment';

//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl, orgurl, producturl, loginurl}  from '../../environment';

const getRemoteData=(url)=>{
    return new Promise((resolve, reject)=>{
        fetch(url).then(response=>response.json()).then((data)=>{resolve(data)})
    })
}

export const companytaxfunctions={

    getCompanyTaxesById:function(taxids){
        var rtn=[];

        taxids.map((t)=>{
            rtn.push(getRemoteData(orgurl+'/api/org/getcompanytax/'+t))
        })
        return Promise.all(rtn);

       
    }
    
};