'use strict';
import moment from 'moment';

//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl, orgurl, producturl, loginurl}  from '../../environment';

const getRemoteData=(url)=>{
    return new Promise((resolve, reject)=>{
        fetch(url).then(response=>response.json()).then((data)=>{resolve(data)})
    })
}

export const eventfunctions={

    eventHasStarted:function(eventstartdate){

        console.info("eventHasStarted->eventstartdate: ");
        console.info(eventstartdate);
        
        if(eventstartdate !=null){
            const eTime=moment().unix()*1000;

            if(eventstartdate[0]?.epochtime>eTime){
                return false;
    
            }else{
                return true;
            }

        }else{
            return true
        }
       
    },

    getAllValidEvnts:function(){

        return new RestServer().setUrl(producturl+'/api/prod/allvalidevents')
        .setMethod('GET')
        .flagReturnData(true)
        .connect()
        .then(response=>{
            console.info("getAllValidEvnts->response: ");
            console.info(response)
            return response.data;
        });
    }
};