//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl, orgurl, producturl, loginurl}  from '../../environment';


const getRemoteData=(url)=>{
    return new Promise((resolve, reject)=>{
      fetch(url).then(response=>response.json()).then((data)=>{resolve(data)})
    })
  }

export const cartfunctions={

    getCart:function(){
        const cdata = JSON.parse(localStorage.getItem('usercart'));
        return cdata;

    },
    
    cartOnlineCheck:function(cartData){

        
        return new RestServer().setUrl(conurl+'/api/cms/validatecartonline')
         .setMethod('POST')
         .flagReturnData(true)
         .setPostBody(cartData)
         .connect()
         .then(response=>{
             const rtndata =response.data;
             if(rtndata.messageTypeID===0){
                return false;

             }else{
                return true;

             }

         },[]);  
    },
    validateCart:function(){
        
       
        const cdata = JSON.parse(localStorage.getItem('usercart'));

        const venues_data=[];
        var venueIds=[];
        var cartVenueFilterProduct=[]

        cdata?.products?.forEach((p)=>{

            venues_data.push(getRemoteData(orgurl+'/api/org/byvenueid/'+p.venueid));
            console.info("cartfunctions->validateCart->p");
            console.info(p);
        });

        //validate
        Promise.all(venues_data).then((allvenues)=>{
            allvenues.forEach((v)=>{
                console.info("allvenues->v: ");
                console.info(v)
                venueIds.push(v.venueid);
            })
        }).then(()=>{
            console.info("validateCart->venueIds: ");
            console.info(venueIds);
    
            venueIds?.map((vid)=>{
                console.info("vid: ");
                console.info(vid);
                
            })

        })

        
       

    
    },
    decToCart:function(data){
        const cdata = JSON.parse(localStorage.getItem('usercart'))
        var cartdata=null;

        //APPEND PRODUCT OR ADD
        var productsarr=[];
        if(cdata === null){
           
            productsarr.push(data);
            cartdata = {
                products:productsarr,
            }
        }else{
            var prodpush=true;
            cdata.products.map((prod)=>{
                if(data.productid==prod.productid){
                    prod.quanity=prod.quanity-1;
                    productsarr.push(prod);
                    prodpush=false;
                }else{
                    productsarr.push(prod);
                } 
            })

            if(prodpush){
                productsarr.push(data);
            }

            cartdata = {
                cartId: cdata.cartId,
                products:productsarr,
                active:cdata.active,
                datecreated:cdata.datecreated
            }
        }

        //UPDATE / CREATE CART
        return new RestServer().setUrl(conurl+'/api/cms/addtocart')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(cartdata)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            localStorage.setItem("usercart", JSON.stringify(rtndata));
        },[]);    

    },
    addToCart:function(data){
        const cdata = JSON.parse(localStorage.getItem('usercart'))
        var cartdata=null;

        //APPEND PRODUCT OR ADD
        var productsarr=[];
        if(cdata === null){
           
            productsarr.push(data);
            cartdata = {
                products:productsarr,
            }
        }else{
            var prodpush=true;
            cdata.products.map((prod)=>{
                if(data.productid==prod.productid){
                    prod.quanity=prod.quanity+1;
                    productsarr.push(prod);
                    prodpush=false;
                }else{
                    productsarr.push(prod);
                } 
            })

            if(prodpush){
                productsarr.push(data);
            }

            cartdata = {
                cartId: cdata.cartId,
                products:productsarr,
                active:cdata.active,
                datecreated:cdata.datecreated
            }
        }

        //UPDATE / CREATE CART
        return new RestServer().setUrl(conurl+'/api/cms/addtocart')
        .setMethod('POST')
        .flagReturnData(true)
        .setPostBody(cartdata)
        .connect()
        .then(response=>{
            const rtndata =response.data;
            localStorage.setItem("usercart", JSON.stringify(rtndata));
        },[]);    

    },
    removeFromCart:function(data){
        var newCartProd=[];
        const cartData = JSON.parse(localStorage.getItem('usercart'))

        cartData?.products?.map((prod)=>{
            if(prod.productid!==data.productId){
                newCartProd.push(prod);
            }
        });

        var newCart={
        active:cartData?.active,
        activetil:cartData?.activetil,
        cartId:cartData?.cartId,
        datecreated:cartData?.datecreated,
        products:newCartProd,
        userid:cartData?.userid
        }

        localStorage.setItem("usercart", JSON.stringify(newCart));
    },
    updateChart:function(data){

    },
    clearCart:function(){
        localStorage.setItem("usercart", null);
        
    }
};