'use strict';
import moment from 'moment';

//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl, orgurl, producturl, loginurl}  from '../../environment';

const getRemoteData=(url)=>{
    return new Promise((resolve, reject)=>{
        fetch(url).then(response=>response.json()).then((data)=>{resolve(data)})
    })
}

export const venuefunctions={

    getVenuesByIds:function(venids){
        var rtn=[];

        venids.map((v)=>{
            rtn.push(getRemoteData(orgurl+'/api/org/byvenueid/'+v))
        })
        return Promise.all(rtn);

       
    }
    
};