//INTERNAL
import RestServer from '../../components/cRestServer';
import {conurl, orgurl, producturl, loginurl}  from '../../environment';


const getRemoteData=(url)=>{
    return new Promise((resolve, reject)=>{
        fetch(url).then(response=>response.json()).then((data)=>{resolve(data)})
    })
}

export const productfunctions={

    getProductsByIds:function(productIds){
        var rtn=[];

        productIds.map((pid)=>{
            rtn.push(getRemoteData(producturl+'/api/prod/productbyid/'+pid))
        })
        return Promise.all(rtn);

       
    }

};