import React from 'react';

/*BOOTSTRAP*/
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
//import Image from 'react-bootstrap/Image';

//ICONS
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faTicketAlt } from '@fortawesome/free-solid-svg-icons';



function TicketLocations(){
    return(
        <React.Fragment>
            <Row>
                   <Col lg={1}></Col>
                   <Col lg={10}>
                        <Row> 
                            <Col lg={8}>Arena</Col>
                            <Col lg={4}>
                                <Row>
                                    <Col> <Button variant="outline-secondary">Lowest Price</Button>{' '}</Col>
                                    <Col> <Button variant="outline-secondary">Best Seats</Button>{' '}</Col>
                                    <Col><Button variant="outline-secondary">VIP Seats</Button>{' '}</Col>
                                </Row>
                                <Row>
                                    <Col>
                            <Row className="p-t-50 p-b-10 l-b-1">
                                                <Col lg={2}><FontAwesomeIcon icon={faTicketAlt} className="font-250"/></Col>
                                                <Col  lg={8}>
                                                    <Row><span className=" font-85 font-heavy">ticket.ticketname</span></Row>
                                                    <Row><span className=" font-85 clr-dark-grey">Sales end on this.state.eventdate.epochtime </span></Row>
                                                </Col>
                                                <Col><span className="font-150">ticket.priceperticket</span></Col>
                                            </Row>
                                  
                                        
                                    </Col>
                                </Row>
                                
                            </Col>
                        </Row>
                   </Col>
                   <Col lg={1}></Col>
                </Row>       
        </React.Fragment>
    );
}
export default TicketLocations;